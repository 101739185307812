.app-footer {
  letter-spacing: 1px;
  font-family: 'Founders Grotesk', sans-serif;
  background-color: #f4f4f4;

  &-contact {
    padding: 20px 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
      font-weight: 400 !important;
      font-size: 13px;
      margin: 0;
    }

    &__phone {
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 2px;
      line-height: 1.2;
      margin: 0;
    }

    &__hours {
      font-size: 13px;
      font-weight: 300;
      line-height: 1.4;
      margin: 0;
    }
  }

  &-nav {

    &__link {
      display: flex;
      align-items: center;
      padding: 4px 5%;
      color: #666;
      font-size: 13px;
      line-height: 2;
      letter-spacing: 1px;
      &:last-of-type{
        margin-bottom: 20px;
      }
    }
    &__list{
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }
    &__item{
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #e3e3e3;
    }
    &__header{
      display: flex;
      align-items: center;
      padding: 20px 5%;
      margin: 0;
      color: #000;
      font-weight: 200 !important;
      font-size: 13px;
      line-height: 2;
      letter-spacing: 1px;
      position: relative;
      text-transform: uppercase;
      &::before{
        content: '';
        position: absolute;
        top: 45%;
        right: 5%;
        width: 12px;
        height: 12px;
        border-color: #999;
        border-width: 0 2px 2px 0;
        border-style: solid;
        transform: translateY(-50%) rotate(45deg);
        transition: transform .3s ease;
      }
      &.active{
        &::before{
          transform: translateY(-50%) rotate(-135deg);
        }
      }
      &--link{
        &::before{
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
    &__body{
      display: flex;
      flex-direction: column;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;
      &.active{
        max-height: 100dvh;
      }
    }
  }

  &-info {
    padding: 20px 5% 80px;
    background-color: #fff;
    text-align: center;
    color: #666;

    &__copyright {
      margin: 0;
      font-size: 11px;
      font-weight: 400;
      line-height: 1.4;
    }

    &__country {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin: 10px 0;

      &-img {
        width: 25px;
        border: 1px solid #e3e3e3;
      }

      span {
        font-size: 12px;
        font-weight: 300;
        display: inline-flex;
        padding-right: 13px;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          top: 45%;
          right: 0;
          width: 6px;
          height: 6px;
          border-color: #999;
          border-width: 0 1px 1px 0;
          border-style: solid;
          transform: translateY(-50%) rotate(45deg);
          transition: transform .3s ease;
        }
      }
    }

    &__links{
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    &__link{
      font-size: 10px;
      font-weight: 400;
      color: #666;
      position: relative;
      &:not(:first-of-type){
        margin-left: 10px;
        padding-left: 10px;
        &::before{
          content: '|';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
}
