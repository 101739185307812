.need-help-button {
  position: fixed;
  bottom: 4px;
  left: 6px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 999999 !important;
  width: 70px;
  height: 70px;
}

.need-help-button img {
  margin-right: 4px;
  width: 70px;
  height: 70px;
  pointer-events: none;
}

iframe[id="launcher"] {
  display: none !important;
}
