.upload-prescription-m-sidebar {
  margin-top: 0 !important;

  &__back-to {
    width: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  &__order-id {
    font-size: 14px;
    line-height: 24px;
    color: #444444b2;
    font-family: 'Founders Grotesk', sans-serif;
  }

  &__divider {
    margin-top: 32px;
  }

  &__cross {
    display: none;
  }

  &__heading {
    margin-top: 32px;
    color: #000042;
    font-family: 'Founders Grotesk', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .od-pc-image {
      height: 140px;
    }

    &__order-id {
      font-size: 14px;
      line-height: 20px;
      font-family: 'NeuzeitGroReg', sans-serif;
    }

    &__title {
      font-size: 14px;
      line-height: 20px;
      font-family: 'NeuzeitGroReg', sans-serif;
      margin-top: 8px;
    }

    &__description {
      font-size: 12px;
      line-height: 18px;
      margin-top: 8px;
    }
  }

  #navigation-drawer-backdrop {
    position: static;
  }

  &__title {
    padding: 32px 24px 24px;
    font-family: 'Founders Grotesk', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.48px;
    color: #000;
  }

  &__content {
    position: relative;
    padding: 0 24px;
    height: 80vh;

    .upload-prescription-m-sidebar__collapaseMenu {
      background: #fff;
      border-radius: 12px;
      user-select: none;
      margin-bottom: 32px;
      border: 1px solid #ddd;

      &-title {
        font-family: 'Founders Grotesk', sans-serif;
        color: #414b56;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
      }
      &-body {
        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          color: #000;
          font-family: 'NeuzeitGroReg', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.28px;
          cursor: pointer;

          div {
            display: flex;
            align-items: center;
            gap: 16px;
          }
        }
      }
    }
  }

  .od-custom-error-msg {
    font-size: 12px;
    line-height: 24px;
    color: #a94442;
  }
}

.saved-prescription-m-sidebar-panel {
  height: 50vh;
  overflow: hidden;
  overflow-y: scroll;

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    background-color: #fafafa;
    button {
      width: 50%;
    }
  }
  .saved-prescription-m-radio {
    background-color: #fafafa;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    cursor: pointer;

    &__left {
      &_name {
        color: #444;
        font-family: 'Founders Grotesk', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        letter-spacing: -0.24px;
      }
      &_date {
        color: rgba(68, 68, 68, 0.7);
        font-family: 'Founders Grotesk', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        letter-spacing: -0.24px;
      }
    }
    &__right {
      &_check_uncheck {
        width: 18px;
      }
    }
  }
}

.upload-prescription-m-sidebar-panel {
  .od-prescriptions-loader {
    max-width: 232px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 34px;
    margin: 0 auto;

    &__title {
      font-size: 20px;
      line-height: 32px;
      font-family: 'Founders Grotesk', sans-serif;
      color: #000042;
    }
  }

  &__img {
    margin-bottom: 24px;
  }

  &__heading-footer-m {
    font-family: 'Founders Grotesk', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    list-style: disc;
    list-style-type: disc;
    margin-bottom: 16px;
    color: #333368;
  }

  &__list {
    padding-left: 24px;
    &_item {
      font-family: 'Founders Grotesk', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.32px;
      list-style: disc;
      list-style-type: disc;
      margin-bottom: 24px;
      color: #333368;
      max-width: 260px;
      word-wrap: break-word;
    }
  }

  &__uploadBtn {
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    .upload-prescription-btn-change-file {
      color: #546073;
      background-color: #fafafa;
      border: none;
      font-weight: 400;
    }

    .upload-prescription-btn-upload {
      border: none;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
}

.navigation-drawer-sidenav {
  height: auto;
}

#navigation-drawer-backdrop {
  z-index: 1;
}
