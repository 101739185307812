.od-upload-prescription-m {
    padding: 0px 24px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;

    &__separator {
        width: 100%;
        border: 1px solid #E2E2EE;
        margin: 32px 0;
    }

    &__productImage {
        padding-top: 32px;
    }

    &__title {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #000000;
        margin-bottom: 30px;
    }
    &__upload {
        color: #808080;
        display: flex;
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            text-decoration: none;
            color: #808080;
            img {
                max-width: 20px;
                margin-right: 10px;
            }
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
    &__divider {
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 30px;
    }
    &__para {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        color: #808080;
        margin-bottom: 30px;
    }
    &__footer {
        margin-top: 96px;
    }
    &__para-big {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #808080;
    }
}