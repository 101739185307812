.od-breadcrumb-mobile {
    padding: 16px 24px 0;
    font-size: 16px;

    .breadcrumb-separator {
        padding: 0 12px;
        color: #444;
        opacity: 0.3;
    }

    .breadcrumb-item a {
        color: #444;
        opacity: 0.3;
    }

    .breadcrumb-active a {
        color: #444;
        opacity: 0.5;
    }
}
