.app-footer {
  letter-spacing: 1px;
  font-family: 'Founders Grotesk', sans-serif;
  background-color: #f4f4f4;
}

.app-footer-contact {
  padding: 20px 5%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.app-footer-contact__title {
  font-weight: 400 !important;
  font-size: 13px;
  margin: 0;
}

.app-footer-contact__phone {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 1.2;
  margin: 0;
}

.app-footer-contact__hours {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.4;
  margin: 0;
}

.app-footer-nav__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 4px 5%;
  color: #666;
  font-size: 13px;
  line-height: 2;
  letter-spacing: 1px;
}

.app-footer-nav__link:last-of-type {
  margin-bottom: 20px;
}

.app-footer-nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.app-footer-nav__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-bottom: 1px solid #e3e3e3;
}

.app-footer-nav__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 5%;
  margin: 0;
  color: #000;
  font-weight: 200 !important;
  font-size: 13px;
  line-height: 2;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
}

.app-footer-nav__header::before {
  content: '';
  position: absolute;
  top: 45%;
  right: 5%;
  width: 12px;
  height: 12px;
  border-color: #999;
  border-width: 0 2px 2px 0;
  border-style: solid;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
}

.app-footer-nav__header.active::before {
  -webkit-transform: translateY(-50%) rotate(-135deg);
          transform: translateY(-50%) rotate(-135deg);
}

.app-footer-nav__header--link::before {
  -webkit-transform: translateY(-50%) rotate(-45deg);
          transform: translateY(-50%) rotate(-45deg);
}

.app-footer-nav__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
}

.app-footer-nav__body.active {
  max-height: 100dvh;
}

.app-footer-info {
  padding: 20px 5% 80px;
  background-color: #fff;
  text-align: center;
  color: #666;
}

.app-footer-info__copyright {
  margin: 0;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.4;
}

.app-footer-info__country {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 8px;
  margin: 10px 0;
}

.app-footer-info__country-img {
  width: 25px;
  border: 1px solid #e3e3e3;
}

.app-footer-info__country span {
  font-size: 12px;
  font-weight: 300;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-right: 13px;
  position: relative;
}

.app-footer-info__country span::before {
  content: '';
  position: absolute;
  top: 45%;
  right: 0;
  width: 6px;
  height: 6px;
  border-color: #999;
  border-width: 0 1px 1px 0;
  border-style: solid;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
}

.app-footer-info__links {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.app-footer-info__link {
  font-size: 10px;
  font-weight: 400;
  color: #666;
  position: relative;
}

.app-footer-info__link:not(:first-of-type) {
  margin-left: 10px;
  padding-left: 10px;
}

.app-footer-info__link:not(:first-of-type)::before {
  content: '|';
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
