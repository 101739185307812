

.inline-block {
    display: inline-block;
}

.valign-middle {
    vertical-align: middle;
}
.overflow-hidden {
    overflow: hidden;
}
.inline {
    display: inline;
}

.block {
    display: block;
}

.display-flex {
    display: flex;
}

.display-none {
    display: none;
}

/* Flex Classes */

.flex-wrap {
    flex-wrap: wrap;
}

.flex-dot5 {
    flex: 0.5;
}

.flex-dot6 {
    flex: 0.6;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-10 {
    flex: 10;
}
@for $i from 4 through 25 {
    $value: $i*4;
    .flex-basis-#{$value} {
        flex-basis: #{$value}#{'%'};
    }
}

.flex-basis-15 {
    flex-basis: 15%;
}

.flex-basis-85 {
    flex-basis: 85%;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-around {
    justify-content: space-around;
}

.justify-content-center {
    justify-content: center;
}
.justify-content-start {
    justify-content: flex-start;
}
.justify-content-end {
    justify-content: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.align-self-center {
    align-self: center;
}

.align-self-start {
    align-self: flex-start;
}

.align-self-end {
    align-self: flex-end;
}

.flex-direction-reverse {
    flex-direction: row-reverse;
}

.flex-direction-column {
    flex-direction: column;
}
.display-center {
    display: flex;
    align-items: center;
}
.display-center-justify {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Grid Classes */

.display-grid {
    display: grid;
}

@for $i from 1 through $maxGridCols {
    $value: $i;
    .gridCol#{$value} {
        grid-template-columns: repeat($i, 1fr);
    }
}

@for $i from 1 through $maxGridRows {
    $value: $i;
    .gridRow#{$value} {
        grid-template-rows: repeat($i, 1fr);
    }
}

.gridGap5 {
    grid-gap: 5px;
}
.gridGap20 {
    grid-gap: 20px;
}
.h100 {
    height: 100%;
}
.w100 {
    width: 100%;
}
.w100vw {
    width: 100vw;
}
.w40vw {
    width: 40vw;
}
.w50vw {
    width: 50vw;
}
.object-fit{
    object-fit: cover;
}
.w60vw {
    width: 60vw;
}
.w25vw {
    width: 25vw;
}
.w16vw {
    width: 16vw;
}
.border-none {
    border: none;
}
.border-blue {
    border: 1px solid #00BAC6;
}
.pd-t2{
    padding-top: 2px;
}
.text-end{
    text-align: end;
}
.w10vw {
    width: 10vw;
}
.ml-l20 {
    margin-left: 20px;
}
.fw700 {
    font-weight: 700;
}
.pd-t10 {
    padding-top: 10px;
}
.pd-l10 {
    padding-left: 10px;
}
.pd-r10 {
    padding-right: 10px;
}
.pd-b10  {
    padding-bottom: 10px;
}
.mr-auto {
    margin: auto;
}
.separator {
    border-top: 1px solid #000000;
    opacity: 0.1;
}
.opacity {
    opacity: 0.5;
}