.mr-auto {
    margin: auto;
}

.mr-0auto {
    margin: 0 auto;
}

@for $i from 0 through $maxSpacingVar {
    $value: $i*5;
    .pd-#{$value} {
        padding: $value + px;
    }
    .pd-t#{$value} {
        padding-top: $value + px;
    }
    .pd-r#{$value} {
        padding-right: $value + px;
    }
    .pd-b#{$value} {
        padding-bottom: $value + px;
    }
    .pd-l#{$value} {
        padding-left: $value + px;
    }
    .mr-#{$value} {
        margin: $value + px;
    }
    .mr-t#{$value} {
        margin-top: $value + px;
    }
    .mr-r#{$value} {
        margin-right: $value + px;
    }
    .mr-b#{$value} {
        margin-bottom: $value + px;
    }
    .mr-l#{$value} {
        margin-left: $value + px;
    }
}

@for $i from 1 through $oddSpacingVar {
    $value: $i*4;
    .pd-#{$value} {
        padding: $value + px;
    }
    .pd-t#{$value} {
        padding-top: $value + px;
    }
    .pd-r#{$value} {
        padding-right: $value + px;
    }
    .pd-b#{$value} {
        padding-bottom: $value + px;
    }
    .pd-l#{$value} {
        padding-left: $value + px;
    }
    .mr-#{$value} {
        margin: $value + px;
    }
    .mr-t#{$value} {
        margin-top: $value + px;
    }
    .mr-r#{$value} {
        margin-right: $value + px;
    }
    .mr-b#{$value} {
        margin-bottom: $value + px;
    }
    .mr-l#{$value} {
        margin-left: $value + px;
    }
}