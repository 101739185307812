/*
 *  Define scss variables here.
*/
.search-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  background: #ffffff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.search-header {
  -webkit-box-shadow: 0 2px 2px 0px rgba(148, 150, 159, .3);
          box-shadow: 0 2px 2px 0px rgba(148, 150, 159, .3);
}
.search-header-back-btn {
  width: 25px;
  height: 21px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 6px;
}
.search-input {
  font-size: 15px;
  border: none transparent;
  outline: none;
  width: 100%;
  height: 40px;
}
.search-results-container {
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
.search-results-container .matching-keywords .search-results-item-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  padding: 10px 20px;
}
.search-results-item {
  text-transform: capitalize;
  padding: 10px 20px;
  font-family: 'NeuzeitGroReg', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #444;
  width: 100%;
}
.search-results-item a {
  color: #000;
}
.search-results-product {
  grid-template-columns: 2fr 3fr 1fr;
  grid-gap: 7px;
}
.search-results-product .product-image {
  border: 1px solid #eaeff4;
}
.qr-button-container {
  width: 100%;
  padding: 20px;
  background: #fff;
  position: fixed;
  bottom: 5vh;
  z-index: 999;
}
