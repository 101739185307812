.od-payment-page-m {
  height: 100vh;
}

.od-payment-page-m__items {
  padding: 0 16px 8px 16px;
}

.od-payment-page-m__success {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  height: calc(100vh - 83px);
}

.od-payment-page-m__success .od-payment-page-m-success-image {
  margin-top: -100px;
}
