.od-auth-page-m__top {
  background: url("https://static1.lenskart.com/media/owndays/img/customer/account/auth-bg_sp.webp") no-repeat center top/cover;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 50px;
}

.od-auth-page-m__top a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 10rem;
}

.od-auth-page-m__top ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 385px;
}

.od-auth-page-m__top ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  background: rgba(76, 76, 76, .4);
  backdrop-filter: blur(4px);
  padding: 10px;
  text-align: center;
}

.od-auth-page-m__top ul li img {
  display: block;
  margin-inline: auto;
  max-width: 32px;
}

.od-auth-page-m__top ul li p {
  font-size: 12px;
  color: #fff;
  margin: 0;
}
