.od-wishlist-page-mobile {
    padding: 40px 30px 30px;
    background-color: #F7F7F7;

    &__layout {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__title {
        margin: 0;
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.48px;
    }

    &__empty {
        color: rgba(0, 0, 0, 0.5);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.48px;
    }

    &__empty-image {
        margin: 64px auto;
    }
}