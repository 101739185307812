@import '../../../../theme/mobile/variables';

.od-header {
  width: 100%;

  &__panel {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    padding: 18px 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-box-shadow: none;
    box-shadow: none;

    @media only screen and (max-width: 362px) {
      padding: 18px 15px;
    }

    @media only screen and (max-width: 300px) {
      padding: 18px 5px;
    }
  }

  .od-header {
    &__toggle {
      display: flex;
      align-items: center;
      background-color: transparent;
      transition-duration: 0.35s;
      transition-property: background-color;
      transition-timing-function: ease;
    }

    &__logo {
      margin-left: 15px;

      &-img {
        max-height: 40px;
        max-width: 140px;
        cursor: pointer;
      }
    }
  }

  .icon {
    font-size: 19px;
    padding-left: 17px;
    color: #000000;
    display: inline-block;
    cursor: pointer;
  }

  .badge {
    position: absolute;
    top: -4px;
    left: 28px;
    color: map-get($colorpallete, color_white);
    background: $badge-background;
    font-weight: 500;
    border-radius: 50%;
    font-size: 11px;
    display: inline-block;
    width: 17px;
    height: 17px;
    text-align: center;
    line-height: 19px;
  }

  .menu.nav-visible {
    background-color: $app-nav-background;

    .icon {
      color: map-get($colorpallete, color_white);
    }
  }
  .width80 {
    width: 80px;
  }
  .width100 {
    width: 100px;
  }
  @media only screen and (max-width: 320px) {
    .icon {
      padding: 12px 3px;
    }
  }
  @media only screen and (max-width: 370px) {
    .icon {
      font-size: 14px;
      padding: 12px 3px;
    }
    .qr-icon {
      width: 14px;
      height: 14px;
      margin-bottom: 4px;
    }
  }

  .fixed-eye-test-button {
    position: absolute;
    right: 16px;
    margin-top: 38px;
    white-space: nowrap;
    background-color: #0094c8;
  }
}
