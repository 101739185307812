.qr-modal {
  position: fixed;
  display: block;
  margin: 0 auto;
  top: 0;
  left: 0;
  z-index: 1200 !important;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  background: #000042;
  width: 100%;
}

.qr-close-btn {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
}
