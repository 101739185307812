.od-auth-page-m__top{
  background: url('https://static1.lenskart.com/media/owndays/img/customer/account/auth-bg_sp.webp') no-repeat center top/cover;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 10rem;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px;
    width: 100%;
    max-width: 385px;
    li{
      display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 10px;
        background: rgba(76, 76, 76, 0.40);
        backdrop-filter: blur(4px);
        padding: 10px;
        text-align: center;
        img{
          display: block;
          margin-inline: auto;
          max-width: 32px;
        }
        p{
          font-size: 12px;
          color: #fff;
          margin: 0;
        }
    }
  }
}