/* Position Classes */
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}
.pos-fix {
  position: fixed;
}
.pos-static {
  position: static;
}
