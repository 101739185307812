.od-payment-methods {
  &__title {
    padding: 24px 16px 16px 16px;
    margin: 0 -16px;
    color: #444;
    background-color: #fafafa;
    font-size: 20px;
    letter-spacing: -0.4px;
  }
}

.od-payment-methods-active {
  padding-top: 8px;
}

.od-payment-methods-options {
  &__option {
    display: flex;
    padding: 12px 0;
    align-items: center;
    gap: 12px;
  }

  .grab-payment {
    border-top: 1px dashed rgba(84, 96, 115, 0.10);
    border-bottom: 1px dashed rgba(84, 96, 115, 0.10);
    align-self: stretch;
  }  

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  &__option-label {
    color: #444;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__option-description {
    overflow: hidden;
    color: rgba(68, 68, 68, 0.70);
    text-overflow: ellipsis;
    font-size: 12px;
    letter-spacing: -0.24px;
  }

  &__radio-input {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid rgba(68, 68, 68, 0.50);
    background: #FFF;
    margin: 0;
  }

  &__others {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;

    img {
      height: 26px;
      width: 44px;
    }
  }
}


.od-payment-methods-checkbox {
  position: relative;

  &__check {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;

      &--active,
      &:hover {
          opacity: 1;
      }
  }
}

.od-payment-methods-more {
  display: flex;
  padding: 16px 0;
  margin-top: 16px;
  align-items: center;
  gap: 12px;

  &__method {
    display: flex;
    width: 46px;
    height: 32px;
    border: 1px solid rgba(84, 96, 115, 0.10);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }
}

.od-payment-methods-options__radio__check-m {
  height: 20px;
  width: 20px;
}