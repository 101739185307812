/* Border Classes */
@for $i from 2 through 20 {
  .bora-#{$i} {
    border-radius: #{$i}px;
  }
}
.bora-10 {
  border-radius: 10px;
}
.bora-50cent {
  border-radius: 50%;
}

.hor-separator {
  display: block;
  width: 1px;
  background: #eaeff4;
}
