.od-cart-bill-details-mobile {
  padding: 0 16px;
}

.od-cart-bill-details-mobile__delivery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px 16px 8px 16px;
  margin: 0 -16px;
  background-color: #FAFAFA;
}

.od-cart-bill-details-mobile__delivery__title {
  color: #444;
  font-size: 20px;
  letter-spacing: -0.4px;
}

.od-cart-bill-details-mobile__delivery__change {
  color: #546073;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.28px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.od-cart-bill-details-mobile .membership-discount__container {
  margin: 8px -16px;
}

.od-cart-bill-details-mobile__title {
  color: #444;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  margin: 0 -16px;
  background-color: #FAFAFA;
  padding: 16px 16px 8px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-cart-bill-details-mobile__body {
  display: grid;
  margin: 16px 0;
  grid-template-columns: 1fr 0fr;
  gap: 8px;
}

.od-cart-bill-details-mobile__item-title {
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.od-cart-bill-details-mobile__shipping-fee {
  color: #4D9855 !important;
}

.od-cart-bill-details-mobile__item-price {
  color: #444;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
  white-space: nowrap;
}

.od-cart-bill-details-mobile__total-title {
  color: #444;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  text-transform: capitalize;
}

.od-cart-bill-details-mobile__total-price {
  color: #444;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  white-space: nowrap;
}

.od-cart-bill-details-mobile__divider {
  width: 100%;
  border: none;
  border-top: 1px dashed rgba(0, 0, 0, .1);
  margin: 0;
  grid-column: span 2;
}

.od-cart-bill-details-mobile__proceed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 16px 24px;
  border: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
  background-color: #546073;
}

.od-cart-bill-details-mobile__unlocked-discount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  padding: 4px 10px 24px 10px;
}

.od-cart-bill-details-mobile__unlocked-discount img {
  width: 16px;
  height: 16px;
}

.od-cart-bill-details-mobile__unlocked-discount span {
  color: #4D9855;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
}

.od-cart-bill-details-mobile__actions {
  padding: 24px 0 16px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 16px;
}

.od-cart-bill-details-mobile__actions__title {
  color: #444;
  font-size: 20px;
  letter-spacing: -0.4px;
}

.od-cart-bill-details-mobile__actions__btns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 24px;
     -moz-column-gap: 24px;
          column-gap: 24px;
}

.od-cart-bill-details-mobile__checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  cursor: pointer;
}

.od-cart-bill-details-mobile__checkbox span {
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  line-height: 24px;
}

.od-cart-bill-details-mobile__checkbox-inner {
  width: 16px;
  height: 16px;
  border-color: #000000;
  border: 2px solid #000000;
  border-radius: 2px;
  cursor: pointer;
}

.od-cart-bill-details-mobile__checkbox-inner--checked {
  background: url(/assets/svg/od-light-checkbox.svg);
  width: 16px;
  height: 16px;
}

.od-cart-bill-details-mobile-features {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px;
  background-color: #FAFAFA;
}

.od-cart-bill-details-mobile-features__feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 8px;
  margin: 8px 0 60px 0;
}

.od-cart-bill-details-mobile-features__image {
  width: 24px;
  height: 24px;
}

.od-cart-bill-details-mobile-features__title {
  color: #444;
  font-size: 12px;
  letter-spacing: -0.24px;
  text-transform: capitalize;
  max-width: 60px;
}

.od-cart-bill-details-mobile-proceed {
  width: 100%;
  padding: 16px;
  border-radius: 10px 10px 0px 0px;
  background: #FFF;
  -webkit-box-shadow: 0px -32px 9px 0px rgba(0, 0, 0, 0), 0px -21px 8px 0px rgba(0, 0, 0, .01), 0px -12px 7px 0px rgba(0, 0, 0, .02), 0px -5px 5px 0px rgba(0, 0, 0, .03), 0px -1px 3px 0px rgba(0, 0, 0, .04);
          box-shadow: 0px -32px 9px 0px rgba(0, 0, 0, 0), 0px -21px 8px 0px rgba(0, 0, 0, .01), 0px -12px 7px 0px rgba(0, 0, 0, .02), 0px -5px 5px 0px rgba(0, 0, 0, .03), 0px -1px 3px 0px rgba(0, 0, 0, .04);
}

.od-cart-bill-details-mobile-proceed__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 16px 24px;
  border: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
  background-color: #546073;
}
