.od-primer-card-m {
  padding: 16px 0;

  &__two-fields-wrapper {
    display: grid;
    margin-top: 12px;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  &__fields-wrapper {
    margin-top: 12px;
  }
}

.od-primer-card-field-m {
  height: 52px;
  overflow: hidden;

  &__input {
    height: 52px;
    width: 100%;
    padding: 0 16px;
    border: none;
    color: #444;
    font-family: 'NeuzeitGroReg', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    background-color: #FAFAFA;
    outline: none;

    &::placeholder {
      opacity: 0.5;
    }
  }
}
