.od-wishlist-page-mobile {
  padding: 40px 30px 30px;
  background-color: #F7F7F7;
}

.od-wishlist-page-mobile__layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 24px;
}

.od-wishlist-page-mobile__title {
  margin: 0;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
}

.od-wishlist-page-mobile__empty {
  color: rgba(0, 0, 0, .5);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
}

.od-wishlist-page-mobile__empty-image {
  margin: 64px auto;
}
