/* ditto section */
#tab-try-ditto {
  #get-started {
    img {
      width: 100%;
    }
    > a#start-ditto {
      background-color: #379b91;
      bottom: 33%;
      color: #fff;
      font-size: 3vw;
      padding: 5px 11px;
      position: absolute;
      right: 4.5%;
      text-transform: uppercase;
      font-weight: bold;
    }
    > a.login-account {
      bottom: 24%;
      color: #625f5e;
      font-size: 2vw;
      padding: 3px;
      position: absolute;
      right: 4%;
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  #try_Ditto {
    .try-now.recreate-btn {
      background: none repeat scroll 0 0 #329c92;
      border: medium none;
      color: #fff;
      font-size: 13px;
      line-height: 28px;
      padding: 3px 10px 0;
      text-shadow: 1px 1px 1px #47736f;
      width: auto;
      text-align: center;
    }
    .try-now {
      background: none repeat scroll 0 0 #888;
    }
  }

  #ditto-3D-icon {
    img {
      position: absolute;
    }
    img:nth-of-type(1) {
      right: 20px;
      top: 50px;
    }
    img:nth-of-type(3) {
      left: 20px;
      top: 50px;
    }
    img:nth-of-type(2) {
      bottom: 70px;
      right: 20px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 520px) {
      img:nth-of-type(2) {
        bottom: 20px;
      }
    }
  }
  #ditto {
    height: 65vw;
  }
}
