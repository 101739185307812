.od-signup-page-m {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100svh;
  position: relative;
  overflow-y: auto;
}

.od-signup-page-m input[type='date'] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 50px;
}

.od-signup-page-m__back {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.od-signup-page-m__linkText {
  text-align: right;
  font-family: 'Founders Grotesk', sans-serif;
  color: #444444;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 24px;
}

.od-signup-page-m .od-signup-page-m-number-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.od-signup-page-m .od-signup-page-m-number-wrapper_code {
  width: 60px;
  padding-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.od-signup-page-m .od-signup-page-m-number-wrapper_code::-webkit-input-placeholder {
  color: #000;
}

.od-signup-page-m .od-signup-page-m-number-wrapper_code::-moz-placeholder {
  color: #000;
}

.od-signup-page-m .od-signup-page-m-number-wrapper_code::-ms-input-placeholder {
  color: #000;
}

.od-signup-page-m .od-signup-page-m-number-wrapper_code::placeholder {
  color: #000;
}

.od-signup-page-m .od-signup-page-m-number-wrapper_number {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.od-signup-page-m__banner {
  height: auto;
}

.od-signup-page-m__content {
  padding: 20px;
}

.od-signup-page-m-h4 {
  font-size: 20px;
  font-family: 'Founders Grotesk', sans-serif;
}

.od-signup-page-m-p {
  font-family: 'NeuzeitGroReg', sans-serif;
  font-size: 14px;
  margin-bottom: 40px;
}

.od-signup-page-m__input {
  display: block;
  padding: 14px 16px;
  background-color: #fafafa;
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 16px;
  width: 100%;
}

.od-signup-page-m__input--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.od-signup-page-m__footer {
  padding: 20px;
  background-color: #fafafa;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.od-signup-page-m__footer__content {
  width: 100%;
}

.od-signup-page-m__footer .divider-line-m {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.od-signup-page-m__footer .divider-line-m-left {
  border: 1px solid rgba(0, 0, 0, 0.06275);
  margin-right: 20px;
  width: 100%;
  height: 1px;
}

.od-signup-page-m__footer .divider-line-m-text {
  font-family: 'Founders Grotesk', sans-serif;
}

.od-signup-page-m__footer .divider-line-m-right {
  border: 1px solid rgba(0, 0, 0, 0.06275);
  margin-left: 20px;
  width: 100%;
  height: 1px;
}

.od-signup-page-m .od-signup-page-m-input-error {
  color: #a94442;
  margin-bottom: 16px;
}

.od-signup-page-m .od-signup-page-m-phone-error {
  color: #a94442;
  margin-bottom: 16px;
}

.od-signup-page-m__sb {
  width: 100%;
  background-color: #A0A0A0;
  padding: 12px 20px;
  border: none;
  border-radius: 40px;
  color: #fff;
  font-size: 20px;
  margin-top: 24px;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.od-signup-page-m__sb:disabled {
  cursor: not-allowed;
}

.od-signup-page-m__sb.terms-accepted {
  background-color: #546073;
}

.od-signup-page-m__dob-container {
  position: relative;
}

.od-signup-page-m__dob-container .react-datepicker-wrapper {
  width: 100%;
}

.od-signup-page-m__dob-container img {
  position: absolute;
  top: 14px;
  right: 16px;
}

.od-signup-page-m select.visitPurpose_od_desktop {
  display: block;
  padding: 14px 16px;
  background-color: #fafafa;
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 16px;
  width: 100%;
}
