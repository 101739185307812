.od-retry-payment-mobile {
  padding: 32px 24px;
  background-color: #F7F7F7;
}

.od-retry-payment-mobile__submit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 16px 24px;
  border: none;
  margin-top: 24px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
  background-color: #546073;
}

.od-retry-payment-mobile-bill-details {
  max-width: 360px;
  margin-top: 32px;
}

.od-retry-payment-mobile-bill-details__title {
  color: #444;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
}

.od-retry-payment-mobile-bill-details__rows {
  display: grid;
  margin-top: 24px;
  margin-bottom: 24px;
  grid-template-columns: auto -webkit-min-content;
  grid-template-columns: auto min-content;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 16px;
  -webkit-column-gap: 32px;
     -moz-column-gap: 32px;
          column-gap: 32px;
  white-space: nowrap;
}

.od-retry-payment-mobile-bill-details__total {
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
  text-transform: capitalize;
}

.od-retry-payment-mobile-bill-details__total-price {
  text-align: right;
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.od-retry-payment-mobile-bill-details__divider {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, .2);
  margin: 4px 0;
  grid-column: span 2;
}

.od-retry-payment-mobile-bill-details__features {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  gap: 12px;
}

.od-retry-payment-mobile-bill-details__feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 7px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
          flex: 1 0 0;
  gap: 8px;
  background: #FAFAFA;
}

.od-retry-payment-mobile-bill-details__image {
  max-width: 48px;
  max-height: 48px;
}

.od-retry-payment-mobile-bill-details__feature-title {
  color: #444;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  text-transform: capitalize;
}

.od-retry-payment-mobile-bill-details__success {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  height: calc(100vh - 83px);
}

.od-retry-payment-mobile-bill-details__success-image {
  margin-top: -100px;
}
