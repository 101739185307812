.od-upload-prescription-m {
  padding: 0px 24px;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
}

.od-upload-prescription-m__separator {
  width: 100%;
  border: 1px solid #E2E2EE;
  margin: 32px 0;
}

.od-upload-prescription-m__productImage {
  padding-top: 32px;
}

.od-upload-prescription-m__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.3px;
  color: #000000;
  margin-bottom: 30px;
}

.od-upload-prescription-m__upload {
  color: #808080;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.od-upload-prescription-m__upload-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  color: #808080;
}

.od-upload-prescription-m__upload-item img {
  max-width: 20px;
  margin-right: 10px;
}

.od-upload-prescription-m__upload-item:not(:last-child) {
  margin-right: 20px;
}

.od-upload-prescription-m__divider {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
}

.od-upload-prescription-m__para {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: #808080;
  margin-bottom: 30px;
}

.od-upload-prescription-m__footer {
  margin-top: 96px;
}

.od-upload-prescription-m__para-big {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #808080;
}
