.od-login-page-m {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100svh;
  position: relative;
}

.od-login-page-m__back {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.od-login-page-m__linkText {
  text-align: right;
  font-family: 'Founders Grotesk', sans-serif;
  color: #444444;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 24px;
}

.od-login-page-m .od-login-page-m-number-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.od-login-page-m .od-login-page-m-number-wrapper_code {
  width: 60px;
  padding-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.od-login-page-m .od-login-page-m-number-wrapper_code::-webkit-input-placeholder {
  color: #000;
}

.od-login-page-m .od-login-page-m-number-wrapper_code::-moz-placeholder {
  color: #000;
}

.od-login-page-m .od-login-page-m-number-wrapper_code::-ms-input-placeholder {
  color: #000;
}

.od-login-page-m .od-login-page-m-number-wrapper_code::placeholder {
  color: #000;
}

.od-login-page-m .od-login-page-m-number-wrapper_number {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.od-login-page-m__banner {
  height: 200px;
}

.od-login-page-m__content {
  padding: 20px;
  height: 100%;
}

.od-login-page-m-h4 {
  font-size: 20px;
  font-family: 'Founders Grotesk', sans-serif;
}

.od-login-page-m-p {
  font-family: 'NeuzeitGroReg', sans-serif;
  font-size: 14px;
  margin-bottom: 40px;
}

.od-login-page-m__input {
  display: block;
  padding: 14px 16px;
  background-color: #fafafa;
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 16px;
  width: 100%;
}

.od-login-page-m__footer {
  padding: 20px;
  background-color: #fafafa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  width: 100%;
}

.od-login-page-m__footer__content {
  width: 100%;
}

.od-login-page-m__footer .divider-line-m {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.od-login-page-m__footer .divider-line-m-left {
  border: 1px solid rgba(0, 0, 0, 0.06275);
  margin-right: 20px;
  width: 100%;
  height: 1px;
}

.od-login-page-m__footer .divider-line-m-text {
  font-family: 'Founders Grotesk', sans-serif;
}

.od-login-page-m__footer .divider-line-m-right {
  border: 1px solid rgba(0, 0, 0, 0.06275);
  margin-left: 20px;
  width: 100%;
  height: 1px;
}

.od-login-page-m .od-login-page-m-input-error {
  color: #a94442;
  margin-bottom: 16px;
  margin-top: 16px;
}

.od-login-page-m .od-login-page-m-phone-error {
  color: #a94442;
  margin-bottom: 16px;
}

.od-login-page-m__sb {
  width: 100%;
  background-color: #546073;
  padding: 12px 20px;
  border: none;
  border-radius: 40px;
  color: #fff;
  font-size: 20px;
  margin-top: 24px;
}

.od-login-page-m select.visitPurpose_od_mobile {
  display: block;
  padding: 14px 16px;
  background-color: #fafafa;
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 16px;
  width: 100%;
}
