.upload-prescription-m-sidebar {
  margin-top: 0 !important;
}

.upload-prescription-m-sidebar__back-to {
  width: 100%;
  padding: 0 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 16px;
}

.upload-prescription-m-sidebar__order-id {
  font-size: 14px;
  line-height: 24px;
  color: rgba(68, 68, 68, 0.69804);
  font-family: 'Founders Grotesk', sans-serif;
}

.upload-prescription-m-sidebar__divider {
  margin-top: 32px;
}

.upload-prescription-m-sidebar__cross {
  display: none;
}

.upload-prescription-m-sidebar__heading {
  margin-top: 32px;
  color: #000042;
  font-family: 'Founders Grotesk', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.upload-prescription-m-sidebar__heading .od-pc-image {
  height: 140px;
}

.upload-prescription-m-sidebar__heading__order-id {
  font-size: 14px;
  line-height: 20px;
  font-family: 'NeuzeitGroReg', sans-serif;
}

.upload-prescription-m-sidebar__heading__title {
  font-size: 14px;
  line-height: 20px;
  font-family: 'NeuzeitGroReg', sans-serif;
  margin-top: 8px;
}

.upload-prescription-m-sidebar__heading__description {
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
}

.upload-prescription-m-sidebar #navigation-drawer-backdrop {
  position: static;
}

.upload-prescription-m-sidebar__title {
  padding: 32px 24px 24px;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.48px;
  color: #000;
}

.upload-prescription-m-sidebar__content {
  position: relative;
  padding: 0 24px;
  height: 80vh;
}

.upload-prescription-m-sidebar__content .upload-prescription-m-sidebar__collapaseMenu {
  background: #fff;
  border-radius: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: 32px;
  border: 1px solid #ddd;
}

.upload-prescription-m-sidebar__content .upload-prescription-m-sidebar__collapaseMenu-title {
  font-family: 'Founders Grotesk', sans-serif;
  color: #414b56;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px 24px;
}

.upload-prescription-m-sidebar__content .upload-prescription-m-sidebar__collapaseMenu-body-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px;
  color: #000;
  font-family: 'NeuzeitGroReg', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
  cursor: pointer;
}

.upload-prescription-m-sidebar__content .upload-prescription-m-sidebar__collapaseMenu-body-item div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
}

.upload-prescription-m-sidebar .od-custom-error-msg {
  font-size: 12px;
  line-height: 24px;
  color: #a94442;
}

.saved-prescription-m-sidebar-panel {
  height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
}

.saved-prescription-m-sidebar-panel__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 16px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fafafa;
}

.saved-prescription-m-sidebar-panel__footer button {
  width: 50%;
}

.saved-prescription-m-sidebar-panel .saved-prescription-m-radio {
  background-color: #fafafa;
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 16px;
  cursor: pointer;
}

.saved-prescription-m-sidebar-panel .saved-prescription-m-radio__left_name {
  color: #444;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.24px;
}

.saved-prescription-m-sidebar-panel .saved-prescription-m-radio__left_date {
  color: rgba(68, 68, 68, .7);
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.24px;
}

.saved-prescription-m-sidebar-panel .saved-prescription-m-radio__right_check_uncheck {
  width: 18px;
}

.upload-prescription-m-sidebar-panel .od-prescriptions-loader {
  max-width: 232px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 34px;
  margin: 0 auto;
}

.upload-prescription-m-sidebar-panel .od-prescriptions-loader__title {
  font-size: 20px;
  line-height: 32px;
  font-family: 'Founders Grotesk', sans-serif;
  color: #000042;
}

.upload-prescription-m-sidebar-panel__img {
  margin-bottom: 24px;
}

.upload-prescription-m-sidebar-panel__heading-footer-m {
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  list-style: disc;
  list-style-type: disc;
  margin-bottom: 16px;
  color: #333368;
}

.upload-prescription-m-sidebar-panel__list {
  padding-left: 24px;
}

.upload-prescription-m-sidebar-panel__list_item {
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  list-style: disc;
  list-style-type: disc;
  margin-bottom: 24px;
  color: #333368;
  max-width: 260px;
  word-wrap: break-word;
}

.upload-prescription-m-sidebar-panel__uploadBtn input {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.upload-prescription-m-sidebar-panel__uploadBtn .upload-prescription-btn-change-file {
  color: #546073;
  background-color: #fafafa;
  border: none;
  font-weight: 400;
}

.upload-prescription-m-sidebar-panel__uploadBtn .upload-prescription-btn-upload {
  border: none;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}

.upload-prescription-m-sidebar-panel__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 16px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 15px;
}

.navigation-drawer-sidenav {
  height: auto;
}

#navigation-drawer-backdrop {
  z-index: 1;
}
