.od-breadcrumb-mobile {
  padding: 16px 24px 0;
  font-size: 16px;
}

.od-breadcrumb-mobile .breadcrumb-separator {
  padding: 0 12px;
  color: #444;
  opacity: 0.3;
}

.od-breadcrumb-mobile .breadcrumb-item a {
  color: #444;
  opacity: 0.3;
}

.od-breadcrumb-mobile .breadcrumb-active a {
  color: #444;
  opacity: 0.5;
}
