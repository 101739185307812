.od-checkout-back {
  width: 100%;
  padding: 16px;
  background-color: #fff;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  left: 0;
  z-index: 100;
}

.od-checkout-back__logo {
  max-height: 40px;
  max-width: 140px;
  cursor: pointer;
}
