.roboto {
  font-family: $base-font-family;
}

.roboto-slab {
  font-family: $heavy-font-family;
}

/* Font Size Classes */
$maxFontSize: 32;
@for $i from 8 through $maxFontSize {
  .fsp#{$i} {
    font-size: $i + px;
  }
}

/* Font Weight Classes */
$maxFontWeight: 9;
@for $i from 1 through $maxFontWeight {
  $value: $i * 100;
  .fw#{$value} {
    font-weight: $value;
  }
}

/* Line Height Classes */
$maxLineHeight: 40;
@for $i from 12 through $maxLineHeight {
  .lh-#{$i} {
    line-height: $i + px;
  }
}
