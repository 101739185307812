.od-payment-success-m {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #fafafa;

  &__banner {
    width: 100%;
    position: relative;
    &__overlay {
      position: absolute;
      inset: 0;
      padding: 16px 24px 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__vector {
      position: absolute;
      left: 54px;
      bottom: -10px;
    }

    &__info {
      color: #fff;

      &__title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 8px;
      }

      &__description {
        font-size: 12px;
        color: #fff;
      }
    }
  }

  .upload-prescription-card {
    &__details {
      font-size: 12px;
      color: #444;
      line-height: 24px;

      &__name {
        margin-bottom: 16px;
      }
    }
  }

  &-container {
    width: 100%;

    &__shopping {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 16px;
      border-radius: 10px 10px 0px 0px;
      background: #fff;
      box-shadow: 0px -32px 9px 0px rgba(0, 0, 0, 0), 0px -21px 8px 0px rgba(0, 0, 0, 0.01),
        0px -12px 7px 0px rgba(0, 0, 0, 0.02), 0px -5px 5px 0px rgba(0, 0, 0, 0.03),
        0px -1px 3px 0px rgba(0, 0, 0, 0.04);

      &-btn {
        border-radius: 50px;
        border: 1px solid #546073;
        color: #546073;
      }
    }

    .od-payment-success-m__title {
      color: #fff;
      font-size: 16px;
      letter-spacing: -0.32px;
    }

    .od-payment-success-m__view-order {
      padding: 12px 24px;
      color: #546073;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border-radius: 60px;
      background: #e9eef6;
      margin-top: auto;
    }

    &-what-next {
      background: rgba(255, 169, 68, 0.1);
      padding: 24px 16px;

      &-title {
        color: #444;
        font-size: 20px;
        letter-spacing: -0.4px;
      }

      &-para {
        margin-top: 16px;
        color: #000;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.od-payment-success-m-img {
  width: 100%;
  min-height: 180px;
  object-fit: cover;
}

.order-products-wrapper {
  padding: 8px 16px 0 16px;

  .swipe-indicator {
    width: 32px;
    height: 4px;
    border-radius: 4px;
    background: #d9d9d9;
    margin: 0 auto;
    margin-bottom: 24px;
  }

  .order-products-title {
    color: #444;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    margin-top: 24px;
  }

  .order-products-description {
    color: rgba(68, 68, 68, 0.7);
    font-size: 14px;
    line-height: 19px;
    margin-top: 8px;
  }

  .order-products {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    overflow-y: auto;
    max-height: 70vh;
    padding-bottom: 20px;

    .order-product {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      &__img {
        width: 74px;
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px 0;
        width: 100%;

        &-row {
          display: flex;
          justify-content: space-between;
          gap: 24px;
        }

        &__price {
          color: #444;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.28px;
          white-space: nowrap;
        }

        &__title {
          color: #444;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.28px;
        }

        &__addon {
          color: rgba(68, 68, 68, 0.5);
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.24px;
        }
      }
    }
  }
}
