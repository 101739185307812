@import "../../../../theme/mobile/variables";

.od-nav {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    pointer-events: none;
    z-index: 999;
    color: black;

    &__separator {
        width: 100%;
        padding: 0 24px;
        
        hr {
            width: 100%;
            border: 1px solid #000000;
            opacity: 0.2;
            margin-top: 12px;
        }
    }

    &.od-nav-visible {
        pointer-events: auto;
    }

    &__unit {
        width: 100%;
        display: flex;
        flex-direction: column;

        &__item {
            margin-top: 24px;

            &-text {
                font-size: 12px;
            }
 
            &-toggler {
                transition: transform 0.3s ease-out;
                transform: rotate(180deg);
    
                &--active {
                    transform: none;
                }
            }
        }

        .od-subcategories {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 12px;
            
            .od-subcategory {
                font-size: 24px;
                line-height: 32px;
                color: #000;
                padding: 0 24px;
            }
        }

        .od-subcategories--open {
            max-height: 1000px;
                transition: max-height 0.3s ease-in-out;
        } 
    }

    .nav-link, .nav-link-accordion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        color: #000000;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    .nav-sublink {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        position: relative;
        padding: 12px 0;
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
    }

    .accordion-group-header.expanded .nav-link-accordion{
        background-color: #FAFAFA;
        font-weight: 700;
    }

    .nav-submenu-accordion {

        &--active {
            background-color: #FAFAFA;
            margin-left: -25px;
            margin-right: -25px;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 12px;
        }
    }

    .od-nav-user-info {
        width: 100%;
        margin-bottom: 6px;

        &__btns {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
        }

        &__btn {
            color: rgba(0, 0, 0, 0.7);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
        }
    }

    &__book-eye-test {
        display: flex;
        width: 100%;
        padding: 10px 20px;
        border-radius: 60px;
        margin-top: 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: #0094C8;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px;
        border: none;

        &:hover {
            background-color: #0094C8;
        }
    }
}

.od-nav .app-nav-menu-container {
    position: relative;
    overflow-y: scroll;
    min-height: 100vh;
    background: #FFFFFF;
    color: #000000;
    padding: 45px 25px;
}
