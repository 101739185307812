/*
 *  Define scss variables here.
*/
.od-userinfo-wrapper {
  padding-bottom: 15px;
  margin: 46px 0 0;
}
.od-userinfo-wrapper .od-userinfo {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.od-userinfo-wrapper .od-userinfo__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.od-userinfo-wrapper .od-userinfo__content__icon {
  margin-right: 15px;
}
.od-userinfo-wrapper .od-userinfo__content__icon img {
  vertical-align: middle;
}
.od-userinfo-wrapper .od-userinfo__content__name {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 16px;
}
.od-userinfo-wrapper .od-userinfo__content__login {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  opacity: 0.7;
}
.od-userinfo-wrapper .od-userinfo__user-btns-toggler {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.od-userinfo-wrapper .od-userinfo__user-btns-toggler--active {
  -webkit-transform: none;
          transform: none;
}
.od-userinfo-wrapper .nav-title {
  margin: 0 0 4px;
  padding: 0 0 4px 2px;
  color: #18cfa8;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 2px solid currentColor;
}
.od-userinfo-wrapper .days-divider {
  background: #FFB400;
  width: 1px;
}
.od-membership-current-rank {
  text-transform: capitalize;
}
