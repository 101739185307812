.od-payment-page-m {
	height: 100vh;

  &__items {
    padding: 0 16px 8px 16px;
  }
    

}
  
.od-payment-page-m__success {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: calc(100vh - 83px);

  .od-payment-page-m-success-image {
    margin-top: -100px;
  }
}
