.od-cart-bill-details-mobile {
	padding: 0 16px;

	&__delivery {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 16px 8px 16px;
		margin: 0 -16px;
		background-color: #FAFAFA;

		&__title {
			color: #444;
			font-size: 20px;
			letter-spacing: -0.4px;
		}

		&__change {
			color: #546073;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: -0.28px;
			text-decoration-line: underline;
		}
	}

	.membership-discount__container {
		margin: 8px -16px;
	}

	&__title {
		color: #444;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.4px;
		margin: 0 -16px;
		background-color: #FAFAFA;
		padding: 16px 16px 8px 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__body {
		display: grid;
		margin: 16px 0;
		grid-template-columns: 1fr 0fr;
		gap: 8px;
	}

	&__item-title {
		color: #444;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.28px;
	}

	&__shipping-fee{
		color: #4D9855 !important;
	}

	&__item-price {
		color: #444;
		text-align: right;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.28px;
		white-space: nowrap;
	}

	&__total-title {
		color: #444;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.32px;
		text-transform: capitalize;
	}

	&__total-price {
		color: #444;
		text-align: right;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.32px;
		white-space: nowrap;
	}

	&__divider {
		width: 100%;
		border: none;
		border-top: 1px dashed rgba(0, 0, 0, 0.10);
		margin: 0;
		grid-column: span 2;
	}
	
	&__proceed {
		display: flex;
		width: 100%;
		padding: 16px 24px;
		border: none;
		justify-content: center;
		align-items: center;
		gap: 12px;
		color: #FFF;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.28px;
		background-color: #546073;
	}

	&__unlocked-discount {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 4px 10px 24px 10px;

		img {
			width: 16px;
			height: 16px;
		}

		span {
			color: #4D9855;
			leading-trim: both;
			text-edge: cap;
			font-size: 16px;
		}
	}

	&__actions {
		padding: 24px 0 16px 0;
		display: flex;
		flex-direction: column;
		gap: 16px;

		&__title {
			color: #444;
			font-size: 20px;
			letter-spacing: -0.4px;
		}

		&__btns {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 24px;
		}
	}

	

  &__checkbox {
    display: flex;
    margin-bottom: 24px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    span {
      font-weight: 400;
      color: #000000;
      font-size: 14px;
      line-height: 24px;
    }
  }

	&__checkbox-inner {
		width: 16px;
		height: 16px;
		border-color: #000000;
		border: 2px solid #000000;
		border-radius: 2px;
		cursor: pointer;
	}

	&__checkbox-inner--checked {
		background: url(/assets/svg/od-light-checkbox.svg);
		width: 16px;
		height: 16px;
	}
}

.od-cart-bill-details-mobile-features {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	background-color: #FAFAFA;

	&__feature {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		margin: 8px 0 60px 0;
	}

	&__image {
		width: 24px;
		height: 24px;
	}

	&__title {
		color: #444;
		font-size: 12px;
		letter-spacing: -0.24px;
		text-transform: capitalize;
		max-width: 60px;
	}
}

.od-cart-bill-details-mobile-proceed {
	width: 100%;
	padding: 16px;
	border-radius: 10px 10px 0px 0px;
	background: #FFF;
	box-shadow: 0px -32px 9px 0px rgba(0, 0, 0, 0.00), 0px -21px 8px 0px rgba(0, 0, 0, 0.01), 0px -12px 7px 0px rgba(0, 0, 0, 0.02), 0px -5px 5px 0px rgba(0, 0, 0, 0.03), 0px -1px 3px 0px rgba(0, 0, 0, 0.04);

	&__action {
		display: flex;
		width: 100%;
		padding: 16px 24px;
		border: none;
		justify-content: center;
		align-items: center;
		gap: 12px;
		color: #FFF;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.28px;
		background-color: #546073;
	}
}