.od-payment-success-m {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  background: #fafafa;
}

.od-payment-success-m__banner {
  width: 100%;
  position: relative;
}

.od-payment-success-m__banner__overlay {
  position: absolute;
  inset: 0;
  padding: 16px 24px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.od-payment-success-m__banner__vector {
  position: absolute;
  left: 54px;
  bottom: -10px;
}

.od-payment-success-m__banner__info {
  color: #fff;
}

.od-payment-success-m__banner__info__title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.od-payment-success-m__banner__info__description {
  font-size: 12px;
  color: #fff;
}

.od-payment-success-m .upload-prescription-card__details {
  font-size: 12px;
  color: #444;
  line-height: 24px;
}

.od-payment-success-m .upload-prescription-card__details__name {
  margin-bottom: 16px;
}

.od-payment-success-m-container {
  width: 100%;
}

.od-payment-success-m-container__shopping {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  padding: 16px;
  border-radius: 10px 10px 0px 0px;
  background: #fff;
  -webkit-box-shadow: 0px -32px 9px 0px rgba(0, 0, 0, 0), 0px -21px 8px 0px rgba(0, 0, 0, .01), 0px -12px 7px 0px rgba(0, 0, 0, .02), 0px -5px 5px 0px rgba(0, 0, 0, .03), 0px -1px 3px 0px rgba(0, 0, 0, .04);
          box-shadow: 0px -32px 9px 0px rgba(0, 0, 0, 0), 0px -21px 8px 0px rgba(0, 0, 0, .01), 0px -12px 7px 0px rgba(0, 0, 0, .02), 0px -5px 5px 0px rgba(0, 0, 0, .03), 0px -1px 3px 0px rgba(0, 0, 0, .04);
}

.od-payment-success-m-container__shopping-btn {
  border-radius: 50px;
  border: 1px solid #546073;
  color: #546073;
}

.od-payment-success-m-container .od-payment-success-m__title {
  color: #fff;
  font-size: 16px;
  letter-spacing: -0.32px;
}

.od-payment-success-m-container .od-payment-success-m__view-order {
  padding: 12px 24px;
  color: #546073;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 12px;
  border-radius: 60px;
  background: #e9eef6;
  margin-top: auto;
}

.od-payment-success-m-container-what-next {
  background: rgba(255, 169, 68, .1);
  padding: 24px 16px;
}

.od-payment-success-m-container-what-next-title {
  color: #444;
  font-size: 20px;
  letter-spacing: -0.4px;
}

.od-payment-success-m-container-what-next-para {
  margin-top: 16px;
  color: #000;
  font-size: 14px;
  line-height: 20px;
}

.od-payment-success-m-img {
  width: 100%;
  min-height: 180px;
  -o-object-fit: cover;
     object-fit: cover;
}

.order-products-wrapper {
  padding: 8px 16px 0 16px;
}

.order-products-wrapper .swipe-indicator {
  width: 32px;
  height: 4px;
  border-radius: 4px;
  background: #d9d9d9;
  margin: 0 auto;
  margin-bottom: 24px;
}

.order-products-wrapper .order-products-title {
  color: #444;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  margin-top: 24px;
}

.order-products-wrapper .order-products-description {
  color: rgba(68, 68, 68, .7);
  font-size: 14px;
  line-height: 19px;
  margin-top: 8px;
}

.order-products-wrapper .order-products {
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
  overflow-y: auto;
  max-height: 70vh;
  padding-bottom: 20px;
}

.order-products-wrapper .order-products .order-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  width: 100%;
}

.order-products-wrapper .order-products .order-product__img {
  width: 74px;
}

.order-products-wrapper .order-products .order-product__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
  padding: 16px 0;
  width: 100%;
}

.order-products-wrapper .order-products .order-product__info-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 24px;
}

.order-products-wrapper .order-products .order-product__info__price {
  color: #444;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.28px;
  white-space: nowrap;
}

.order-products-wrapper .order-products .order-product__info__title {
  color: #444;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.28px;
}

.order-products-wrapper .order-products .order-product__info__addon {
  color: rgba(68, 68, 68, .5);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
}
