.need-help-button {
  position: fixed;
  bottom: 4px;
  left: 6px;
  align-items: center;
  z-index: 999999 !important;
  width: 70px;
  height: 70px;
  
  img {
    margin-right: 4px;
    width: 70px;
    height: 70px;
    pointer-events: none;
  }
}

iframe[id="launcher"] {
  display: none !important;
}


