.material-group {
  position: relative;
  textarea {
    font-size: 16px;
    padding: 4px 0;
    width: 100%;
    display: block;
    border: none;
    color: map-get($colorpallete, color_dark_grey);
    border-bottom: 1px solid map-get($colorpallete, cool_grey);
    background: transparent;
  }
  select {
    font-size: 14px;
    padding: 5px 0;
    width: 100%;
    display: block;
    border: none;
    color: map-get($colorpallete, color_dark_grey);
    border-bottom: 1px solid map-get($colorpallete, cool_grey);
    background: transparent;
    border-radius: 2px;
  }
  select option[disabled] { color: map-get($colorpallete, pale_grey); }
  input:focus, textarea:focus, select:focus {
    outline: none;
    border-bottom: 1px solid map-get($colorpallete, topaz);
  }
  /* LABEL */
  label {
    color: map-get($colorpallete, color_light_grey);
    position: absolute;
    pointer-events: none;
    top: 8px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  /* active state */
  select:focus ~ label,
  select:valid ~ label,
  textarea:focus ~ label,
  textarea:not(:empty) ~ label,
  input:focus ~ label,
  input:not([value=""]) ~ label {
    top: -10px;
    font-size: 10px;
    color: map-get($colorpallete, cool_grey);
  }
  /* BOTTOM BARS */
  .bar {
    position: relative;
    display: block;
  }
  .bar:before,
  .bar:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 0;
    bottom: 1px;
    background-color: map-get($colorpallete, topaz);
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }
  /* active state */
  input:focus ~ .bar:before,
  input:focus ~ .bar:after,
  select:focus ~ .bar:before,
  select:focus ~ .bar:after,
  textarea:focus ~ .bar:before,
  textarea:focus ~ .bar:after {
    width: 50%;
  }
  .apply-btn {
    position: absolute;
    right: 4px;
    top: 0px;
  }
}
/* Material Radio Button */
.md-radio {
  margin: 16px 0;
  &.md-radio-inline {
    display: inline-block;
  }
  input[type="radio"] {
    display: none;
    &:checked + label:before {
      border-color: $md-radio-checked-color;
    }
    &:checked + label:after {
      transform: scale(1);
    }
    &:checked + label {
      font-weight: 500;
    }
  }
  label {
    display: inline-block;
    height: $md-radio-size;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    font-size: $md-radio-size;
    -webkit-tap-highlight-color: transparent;
    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: calc(50% - 6px);
      width: $md-radio-size;
      height: $md-radio-size;
      border: 1px solid map-get($colorpallete, color_cc);
      box-sizing: content-box;
    }
    &:after {
      top: calc(50% - 4px);
      left: $md-radio-size - $md-radio-checked-size;
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
      box-sizing: content-box;
    }
  }
}

.new-btn {
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  letter-spacing: 1px;
}

button.new-btn {
  width: 100%;
  padding: 0;
  border: none;
  font-family: inherit;
  outline: none; 
  &.flex-2 {
    flex: 2;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 35px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff !important;
  background-image: none;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
}

.down-arrow {
  visibility: hidden;
  height: 7px;
  position: absolute; 
  left: 43px;
  top: 11px;
}

.country-code-m-display{
  border: none !important;
  background: none !important;
  box-shadow: none !important; 
  left: 0;
  text-align: center !important;
  color: map-get($colorpallete, cool_grey) !important;
  -webkit-text-fill-color: map-get($colorpallete, cool_grey) !important;
  -webkit-opacity: 1;
  opacity: 1;
  margin: 0;
  ~ div {
    .in-label-translate, .sg-label-translate {
      transform: translate(47px);
    }
    .in-input-padding, .sg-input-padding {
      padding-left: 47px;
    }
    .ae-input-padding, .sa-input-padding {
      padding-left: 65px !important;
    }
    .ae-label-translate, .sa-label-translate {
      transform: translate(60px);
    }
    input:focus ~ label,
    input:not([value=""]) ~ label {
      transform: translateX(0);
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) { _::-webkit-full-page-media, _:future, :root , _::-webkit-full-page-media, _:future, :root , .down-arrow { visibility: visible; } }
.india-width {
  width: 65px !important;
}
.country-code-text-left {
  text-align: left !important;
}
.code-z-index {
  z-index: 100;
}
.uae-width {
  width: 57px !important;
}

.sg-login-country-code {
  z-index: 1;
}