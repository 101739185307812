.od-signup-page-m {
  display: flex;
  flex-direction: column;
  height: 100svh;
  position: relative;
  overflow-y: auto;
  input[type='date'] {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 50px;
  }
  &__back {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }
  &__linkText {
    text-align: right;
    font-family: 'Founders Grotesk', sans-serif;
    color: #444444;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 24px;
  }
  .od-signup-page-m-number-wrapper {
    display: flex;
    &_code {
      width: 60px;
      padding-right: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      &::placeholder {
        color: #000;
      }
    }
    &_number {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  &__banner {
    height: auto;
  }
  &__content {
    padding: 20px;
  }
  &-h4 {
    font-size: 20px;
    font-family: 'Founders Grotesk', sans-serif;
  }
  &-p {
    font-family: 'NeuzeitGroReg', sans-serif;
    font-size: 14px;
    margin-bottom: 40px;
  }
  &__input {
    display: block;
    padding: 14px 16px;
    background-color: #fafafa;
    border: none;
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: 16px;
    width: 100%;

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &__footer {
    padding: 20px;
    background-color: #fafafa;
    flex: 1;
    display: flex;
    align-items: end;
    width: 100%;
    flex-direction: column;
    height: 100%;
    &__content {
      width: 100%;
    }
    .divider-line-m {
      display: flex;
      align-items: center;
      justify-content: center;
      &-left {
        border: 1px solid #00000010;
        margin-right: 20px;
        width: 100%;
        height: 1px;
      }
      &-text {
        font-family: 'Founders Grotesk', sans-serif;
      }
      &-right {
        border: 1px solid #00000010;
        margin-left: 20px;
        width: 100%;
        height: 1px;
      }
    }
  }
  .od-signup-page-m-input-error {
    color: #a94442;
    margin-bottom: 16px;
  }
  .od-signup-page-m-phone-error {
    color: #a94442;
    margin-bottom: 16px;
  }
  &__sb {
    width: 100%;
    background-color: #A0A0A0; // Lighter gray color when terms are not accepted
    padding: 12px 20px;
    border: none;
    border-radius: 40px;
    color: #fff;
    font-size: 20px;
    margin-top: 24px;
    transition: background-color 0.3s ease; // Add transition for smooth color change

    &:disabled {
      cursor: not-allowed;
    }

    &.terms-accepted {
      background-color: #546073; // Original color when terms are accepted
    }
  }

  &__dob-container {
    position: relative;

    .react-datepicker-wrapper {
      width: 100%;
    }

    img {
      position: absolute;
      top: 14px;
      right: 16px;
    }
  }

  select.visitPurpose_od_desktop {
    display: block;
    padding: 14px 16px;
    background-color: #fafafa;
    border: none;
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: 16px;
    width: 100%;
  }
}
