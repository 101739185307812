.od-payment-methods__title {
  padding: 24px 16px 16px 16px;
  margin: 0 -16px;
  color: #444;
  background-color: #fafafa;
  font-size: 20px;
  letter-spacing: -0.4px;
}

.od-payment-methods-active {
  padding-top: 8px;
}

.od-payment-methods-options__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
}

.od-payment-methods-options .grab-payment {
  border-top: 1px dashed rgba(84, 96, 115, .1);
  border-bottom: 1px dashed rgba(84, 96, 115, .1);
  -ms-flex-item-align: stretch;
      align-self: stretch;
}

.od-payment-methods-options__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 4px;
}

.od-payment-methods-options__option-label {
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.od-payment-methods-options__option-description {
  overflow: hidden;
  color: rgba(68, 68, 68, .7);
  text-overflow: ellipsis;
  font-size: 12px;
  letter-spacing: -0.24px;
}

.od-payment-methods-options__radio-input {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid rgba(68, 68, 68, .5);
  background: #FFF;
  margin: 0;
}

.od-payment-methods-options__others {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.od-payment-methods-options__others img {
  height: 26px;
  width: 44px;
}

.od-payment-methods-checkbox {
  position: relative;
}

.od-payment-methods-checkbox__check {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.od-payment-methods-checkbox__check--active, .od-payment-methods-checkbox__check:hover {
  opacity: 1;
}

.od-payment-methods-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 0;
  margin-top: 16px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
}

.od-payment-methods-more__method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 46px;
  height: 32px;
  border: 1px solid rgba(84, 96, 115, .1);
  border-radius: 4px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-payment-methods-options__radio__check-m {
  height: 20px;
  width: 20px;
}
