/* Text Classes */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-transform-none {
  text-transform: none;
}
.text-underline {
  text-decoration: underline;
}
.strike-through {
  text-decoration: line-through;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nowrap {
  white-space: nowrap;
}

/* Float Classes */
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

/* Letter Spacing */
.letterSp1 {
  letter-spacing: 1px;
}

/* Alignment mostly of text and image */
.valign-middle {
  vertical-align: middle;
}
.no-wrap {
  white-space: nowrap;
}
