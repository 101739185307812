/*
 *  Define scss variables here.
*/
.od-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  pointer-events: none;
  z-index: 999;
  color: black;
}
.od-nav__separator {
  width: 100%;
  padding: 0 24px;
}
.od-nav__separator hr {
  width: 100%;
  border: 1px solid #000000;
  opacity: 0.2;
  margin-top: 12px;
}
.od-nav.od-nav-visible {
  pointer-events: auto;
}
.od-nav__unit {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.od-nav__unit__item {
  margin-top: 24px;
}
.od-nav__unit__item-text {
  font-size: 12px;
}
.od-nav__unit__item-toggler {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.od-nav__unit__item-toggler--active {
  -webkit-transform: none;
          transform: none;
}
.od-nav__unit .od-subcategories {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}
.od-nav__unit .od-subcategories .od-subcategory {
  font-size: 24px;
  line-height: 32px;
  color: #000;
  padding: 0 24px;
}
.od-nav__unit .od-subcategories--open {
  max-height: 1000px;
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
}
.od-nav .nav-link, .od-nav .nav-link-accordion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  color: #000000;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.od-nav .nav-sublink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  position: relative;
  padding: 12px 0;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.od-nav .accordion-group-header.expanded .nav-link-accordion {
  background-color: #FAFAFA;
  font-weight: 700;
}
.od-nav .nav-submenu-accordion--active {
  background-color: #FAFAFA;
  margin-left: -25px;
  margin-right: -25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 12px;
}
.od-nav .od-nav-user-info {
  width: 100%;
  margin-bottom: 6px;
}
.od-nav .od-nav-user-info__btns {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
.od-nav .od-nav-user-info__btn {
  color: rgba(0, 0, 0, .7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}
.od-nav__book-eye-test {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 10px 20px;
  border-radius: 60px;
  margin-top: 30px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  background-color: #0094C8;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  border: none;
}
.od-nav__book-eye-test:hover {
  background-color: #0094C8;
}
.od-nav .app-nav-menu-container {
  position: relative;
  overflow-y: scroll;
  min-height: 100vh;
  background: #FFFFFF;
  color: #000000;
  padding: 45px 25px;
}
