@import "../vendor/font-awesome.min";

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: none
}

:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

:not(input):not(textarea):not(button) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent
}

body {
    font: #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: map-get($colorpallete, dark_grey_blue);
    margin: 0;
    height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: 100%;
}

button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    outline: 0
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

a {
    text-decoration: none;
    color: map-get($colorpallete, topaz);
    outline: none;
}

ul, ol, dl, p, h1, h2, h3, h4, h5, h6, address, form, table, blockquote, applet, embed, object, iframe, frameset {
    margin: 0 0 $base-text-interval;
}

html, body, div, span, applet, embed, object, iframe, frameset, p, q, blockquote, sub, sup, a, abbr, acronym, font, img, small, center, h1, h2, h3, h4, h5, h6, b, strong, big, u, ins, i, cite, em, var, dfn, address, s, strike, del, dl, dt, dd, ol, ul, li, pre, code, kbd, samp, tt, fieldset, legend, form, label, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
    border: 0;
    background: none;
}

b, h1, h2, h3, h4, h5, h6, strong, th {
    font-weight: 700;
}

h1 {
    font-size: 200%;
}

article, aside, details, figcaption, figure, footer, header, main, nav, section, summary {
    display: block;
}

.animate-slide-down {
    overflow-y: scroll;
    max-height: 100vh;
    transition-property: all;
    transition-duration: .4s;
    transition-timing-function: linear;
}

.transform-middle {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.accordion-group {
    .collapse {
        transition-duration: .5s;
    }
    .collapse.in {
        transition-duration: 1.5s;
    }
}

.icon:before {
    margin: 0 !important;
}

::-webkit-scrollbar { 
    display: none;  
}
* {
    -ms-overflow-style: none;  
    scrollbar-width: none;
}
.pac-container {
    color: #999;
    font-family: "Roboto";
    width: 353px;
    .pac-item {
        line-height: 40px;
        padding-left: 8px;
    }
    .pac-icon {
        display: none;
    }
    &.pac-logo{
       &:after {
           display: none;
       }
    }
}
@for $i from 0 through 5 {
    .ls-#{$i} {
        letter-spacing: #{$i}px;
    }
}
.clearfix {
    clear: both;
}
.float-left {
    float: left;
}
.justify-space-evenly {
    justify-content: space-evenly;
}
.justify-space-between {
    justify-content: space-between;
}
.cursor-pointer {
    cursor: pointer;
}
.strike-through {
    text-decoration: line-through;
}
.blur-text, .blur-text label {
    color: transparent;
    text-shadow: 0 0 1.5px rgba(0, 0, 0, .3);
    cursor: not-allowed;
}

.od-container {
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
}

.od-font-bold {
    font-family: "Founders Grotesk", sans-serif;
}
  
.od-font-light {
    font-family: "Founders Grotesk", sans-serif;
}
  
.od-font-reg {
    font-family: "Founders Grotesk", sans-serif;
}

a[href^=tel] {
    text-decoration:inherit;
    color: inherit;
}
 
.od-membership-status-bronze {
    color: #8E5010;
  }
  
  .od-membership-status-silver {
    color: #939393;
  }
  
  .od-membership-status-gold {
    color: #C7A907;
  }

@media only screen and (max-width: 899px) {
    .od-c-breadcrumb {
        display: flex;
        padding: 30px 24px 0;
        align-items: center;
        font-size: 16px;
        overflow: hidden;
        overflow-x: scroll;
        margin-bottom: 10px;

        & > ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 6px;
        }

        li {
            display: flex;
            align-items: center;
            color: #444;
            white-space: nowrap;
            opacity: 0.3;

            &::after {
                content: '>';
                padding: 0 12px;
                color: #444;
                opacity: 0.3;
            }
        
            &:last-child {
                font-family: 'Founders Grotesk', sans-serif;
        
                &::after {
                    content: '';
                    padding: 0;
                }
            }

            a {
                color: #444;
                white-space: nowrap;
                opacity: 0.5;
            }
        }
    }
}
