@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&family=Zen+Old+Mincho&display=swap");
@import url("https://fonts.cdnfonts.com/css/post-no-bills-colombo");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/webfonts/fa-brands-400.woff2) format("woff2"),
  url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/webfonts/fa-brands-400.ttf) format("truetype")
}

// Fonts
$font-zen-old: "Zen Old Mincho", serif;
$font-zen-new: "Zen Kaku Gothic New", sans-serif;
$font-heading: "Post No Bills Colombo", sans-serif;
$font-inter: "Inter", sans-serif;

// Colors
$black: #000;
$white: #fff;
$gold: #c18b35;
$gold-light: #ffd086;
$red: #b31111;
$red-light: #ff8383;
$brown: #66430b;
$dark: #150707;
$dark-2: #191313;
$grey: #999;
$grey-2: #666;

.l-zeon {
  color: $white;
  background-color: $black;
  font: 500 14px/220% $font-zen-new;
  letter-spacing: normal;
  min-height: 100vh;
  overflow: hidden;

  // Reset
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    color: inherit;
    background: none;
  }

  ul,ol {
    list-style: none;
  }
  button {
    cursor: pointer;
    background-color: unset;
  }
  button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
  }
  a {
    cursor: pointer;
    text-decoration: none !important;
    outline: none;
    display: block;
  }

  // Container
  &__container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: calc(1280px + 40px);
  }

  // Heading
  &__heading {
    font: 400 36px/28px $font-heading;
    letter-spacing: 3.6px;
    display: flex;
    span {
      font: inherit;
      letter-spacing: inherit;
      position: relative;
    }
    &--border {
      span {
        padding-bottom: calc(19px + 13px);
        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 17px;
          border-top: 1px solid $white;
        }
        &::before {
          bottom: 13px;
          width: 84px;
        }
        &::after {
          bottom: 0;
          width: 46px;
        }
      }
    }
    &--center {
      justify-content: center;
      span {
        &::before,
        &::after {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &--bold {
      font-weight: 700 !important;
    }
  }

  // Button
  &__btn {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-radius: 10rem;
    background: $gold url("https://www.owndays.com/images/specials/products/gundam5-zeon/arrow-right.svg") no-repeat right 28px center/8px auto;
    color: $white;
    font: 600 16px/1 $font-zen-new;
    &:hover {
      color: $white;
    }
    &:focus,
    &:active,
    &:visited {
      color: $white;
    }
    &--red {
      background-color: $red;
      border-color: $red;
      border-radius: 8px;
    }
    &--pill {
      border-radius: 10rem;
    }
    &--reservation {
      background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/arrow-up-down.svg");
      background-position: right 24px center;
      background-size: 19px auto;
    }
    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  // Main
  .main {
    background: url("https://www.owndays.com/images/specials/products/gundam5-zeon/main_bg-sp.webp") no-repeat center top/cover;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      background: linear-gradient(
        0deg,
        $black 0%,
        rgba($black, 0.5) 40%,
        rgba($black, 0) 95%
      );
      z-index: 3;
    }
    .l-zeon__container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 150px;
      z-index: 4;
    }
    &__fg {
      position: absolute;
      left: 0;
      right: 0;
      &--1 {
        top: 0;
        z-index: 0;
      }
      &--2 {
        bottom: -60px;
        z-index: 2;
      }
    }
    &__zeon-logo {
      position: absolute;
      top: 26px;
      right: 15px;
      width: 44px;
    }
    &__char {
      position: relative;
      z-index: 1;
      margin-left: -20px;
      margin-right: -20px;
    }
    &__heading {
      display: flex;
      justify-content: center;
      margin-bottom: 75px;
      h1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 17px;
        img {
          max-width: 281px;
          margin: 0 auto;
        }
        span {
          display: inline-flex;
          justify-content: center;
          text-align: center;
          color: $gold;
          border-width: 1px 0 1px 0;
          border-style: solid;
          border-color: $gold;
          font: 900 13px/16px $font-zen-old;
          letter-spacing: 2.08px;
          padding: 5px 28px 7px;
        }
      }
    }
    &__scroll-down {
      display: flex;
      justify-content: center;
      a {
        display: inline-flex;
        width: 100px;
        height: 100px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url("https://www.owndays.com/images/specials/products/gundam5-zeon/scroll-down.svg") no-repeat center/contain;
          animation: rotation 10s infinite linear;
        }
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 32px;
          height: 48px;
          background: url("https://www.owndays.com/images/specials/products/gundam5-zeon/arrow-down.svg") no-repeat center/contain;
          font: 0/0 a;
          transition: all 0.2s ease-in;
        }
      }
    }
    &__period {
      margin-bottom: 2px;
      &-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 94px;
        height: 94px;
        border-radius: 100%;
        background-color: $gold;
        margin-left: auto;
        span {
          font-family: $font-zen-new;
          font-weight: 700;
          &.y {
            font-size: 10px;
            line-height: 17px;
            letter-spacing: 1px;
            margin-top: -6px;
          }
          &.m-d {
            font-size: 20px;
            line-height: 17px;
            letter-spacing: 1.2px;
            margin-top: -2px;
            margin-bottom: 3px;
            small {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              background-color: #7e530d;
              padding: 2px 1px 3px 2px;
              font-size: 8px;
              font-weight: 700;
              line-height: 5px;
              letter-spacing: 0.8px;
              margin-left: 2px;
            }
          }
          &.t {
            font-size: 9px;
            line-height: 6px;
            letter-spacing: 0.18px;
            margin-bottom: 1px;
          }
          &.text {
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 1.2px;
          }
        }
      }
    }
  }

  // Concept
  .concept {
    position: relative;
    z-index: 3;
    margin-top: -1px;
    .l-zeon__heading {
      margin-bottom: 50px;
    }
    &__text {
      padding: 0 30px;
      max-width: 800px + 60px;
      margin: 0 auto 90px;
    }
    &__movie {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;
      &-wrap {
        max-width: 800px + 60px;
        margin: 0 auto;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 64px;
        height: 64px;
        background: url("https://www.owndays.com/images/specials/products/gundam5-zeon/btn_play.svg") no-repeat center/contain;
      }
      img {
        transition: all 0.3s ease;
      }
      iframe {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.3s ease;
      }
    }
  }

  // Lineup
  .lineup {
    &__item {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        background-repeat: repeat-x;
        background-position: center;
        background-size: auto 100%;
      }
      &--glasses {
        padding: 115px 0 0;
        &::before {
          top: -199px;
          bottom: -189px;
          background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/lineup_bg-1-sp.webp");
        }
        .lineup__char {
          top: 289px;
          left: calc(50% - 333px);
        }
      }
      &--sunglasses {
        padding: 120px 0 94px;
        &::before {
          top: -121px;
          bottom: -168px;
          background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/lineup_bg-2-sp.webp");
        }
        .lineup__char {
          top: 248px;
          left: calc(50% - 274px);
        }
      }
    }
    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
    &__char {
      width: 750px;
      height: 1080px;
      position: absolute;
      z-index: 1;
    }
    &__content {
      position: relative;
      z-index: 2;
    }
    &__heading {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      padding: 0 20px;
      margin-bottom: 497px;
      &-logo {
        max-width: 31px;
        margin: 0 auto;
      }
      &-text {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        text-align: center;
        h2 {
          color: $gold;
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          font: 500 26px/160% $font-zen-new !important;
          letter-spacing: 1.3px;

          small {
            font: 900 14px/16px $font-zen-old;
            letter-spacing: 2.24px;
          }
        }
        p {
          font: 500 14px/165% $font-zen-new;
          strong {
            font-family: inherit !important;
            font-weight: 600 !important;
            font-size: 20px;
            margin: 0 0.25em;
          }
          small {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
    &__product {
      position: relative;
      display: flex;
      flex-direction: column;
      &-slider {
        img {
          width: 337px;
          padding: 10px 0;
          background-color: $white;
        }
        .slick {
          display: none;
          &.active {
            display: block;
          }
          &-slide > div {
            display: flex;
            margin: 0 6px;
          }
        }
      }
      &-details {
        padding: 25px 20px 63px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: -125px;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba($black, 0.8);
          background-blend-mode: multiply;
          z-index: -1;
        }
      }
      &-actions {
        display: flex;
        align-items: center;
        margin-bottom: 45px;
        padding: 0 7px;
      }
      &-colors {
        display: flex;
        column-gap: 15px;
        li {
          display: flex;
          button {
            display: flex;
            outline: none;
            padding: 0;
            font: 0/0 a;
            width: 57px;
            height: 26px;
            border: 2px solid transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            cursor: pointer;
            &.active {
              border-color: $gold;
            }
          }
        }
      }
      &-color {
        margin-left: 20px;
        font: 500 14px/165% $font-zen-new;
      }
      &-nav {
        margin-left: auto;
        display: flex;
        align-items: center;
        column-gap: 15px;
        display: none;
        visibility: hidden;
        opacity: 0;

        &-btn {
          outline: none;
          font: 0/0 a;
          padding: 0;
          background-color: $white;
          background-repeat: no-repeat;
          background-size: 16px auto;
          border: 1px solid $grey-2;
          border-radius: 100%;
          width: 80px;
          height: 80px;
          transition: transform 0.3s ease;
          &:hover {
            transform: scale(1.1);
          }
          &--prev {
            background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/chevron-left.svg");
            background-position: 45% center;
          }
          &--next {
            background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/chevron-right.svg");
            background-position: 55% center;
          }
        }
      }
      &-des {
        margin-bottom: 38px;
        text-align: justify;
        padding: 0 30px;
      }
      &-link {
        padding: 0 10px;
      }
    }
  }

  // Accessories
  .accessories {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    .l-zeon__heading {
      margin-bottom: 50px;
    }
    &__inner {
      background-color: $dark-2;
      padding: 100px 30px;
    }
    &__grid {
      display: grid;
      grid-gap: 40px;
    }
  }

  // Stores
  .stores {
    padding: 100px 0;
    .l-zeon {
      &__container {
        max-width: calc(875px + 40px);
      }
      &__heading {
        margin-bottom: 40px;
      }
      &__btn {
        max-width: 700px;
        margin: 0 auto;
      }
    }
    &__text {
      text-align: center;
      font: 500 20px/170% $font-zen-new;
      letter-spacing: 1px;
      small {
        font-weight: inherit;
        font-size: 16px;
        letter-spacing: 0.8px;
      }
      i {
        font-weight: inherit;
        font-size: 9px;
        letter-spacing: 0.45px;
      }
    }
    &__action {
      padding: 0 10px 50px;
    }
    &__notes {
      margin-top: 15px;
      font: 500 12px/220% $font-zen-new;
      display: flex;
      flex-direction: column;
      color: $grey;
      font: 400 13px/220% $font-inter;
      li {
        position: relative;
        padding-left: 1.5em;
        &::before {
          content: "*";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  // Case
  .case {
    position: relative;
    padding: 60px 10px 153px;
    background: url("https://www.owndays.com/images/specials/products/gundam5-zeon/case_bg-sp.webp") no-repeat center top/cover;
    .l-zeon__container {
      position: relative;
      z-index: 1;
    }
    &__content {
      text-align: center;
    }
    &__img {
      position: absolute;
      top: 26px;
      left: 50%;
      transform: translateX(-50%);
      width: 295px;
      z-index: 0;
    }
    &__badge {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 5px 12px 8px;
      background-color: $brown;
      color: $white;
      font: 500 13px/16px $font-zen-new;
      letter-spacing: 3.9px;
    }
    &__heading {
      margin-top: 226px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      font: 900 36px/44px $font-zen-old;
      letter-spacing: 1.44px;
      row-gap: 3px;
      margin-bottom: 12px;
      small {
        font-size: 13px;
        font-weight: inherit;
        line-height: normal;
        letter-spacing: 1.17px;
      }
    }
    &__zeon-owndays {
      text-align: center;
      margin-bottom: 46px;
      h3 {
        max-width: 187px;
        margin: 0 auto;
      }
      p {
        font: 500 12px/28px $font-zen-new;
        letter-spacing: 3px;
        padding: 0 44px 4px;
        border-width: 1px 0 1px 0;
        border-style: solid;
        border-color: $white;
        strong {
          font-weight: 900;
          font-size: 16px;
          line-height: inherit;
          letter-spacing: 2.08px;
        }
      }
    }
    &__action {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  // Share
  .share {
    padding: 60px 0 100px;

    .l-zeon__heading {
      margin-bottom: 26px;
    }
    &__inner {
      background-color: $dark;
      padding: 43px 0 62px;
    }
    &__list {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 40px;
      li {
        a {
          display: inline-flex;
          color: $white;
          font-size: 35px;
        }
      }
    }
  }
}

.fancybox-container {
  .fancybox {
    &-infobar {
      top: calc(50% + 62vw);
      left: 50%;
      transform: translateX(-50%);
      color: $white;
    }
    &-button {
      &--zoom,
      &--play {
        display: none !important;
      }
      &--close {
        background-color: $black;
      }
    }
    &-navigation {
      position: absolute;
      top: calc(50% + 50vw);
      left: 0;
      right: 0;
      z-index: 99997;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 15px;
      .fancybox-button {
        position: static;
        padding: 0;
        width: 60px;
        height: 60px;
        background-color: $white;
        border-radius: 100%;
        border: 1px solid #666666;
        display: flex;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px auto;
        svg {
          display: none !important;
        }
        &--arrow_left {
          background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/nav-prev.svg");
        }
        &--arrow_right {
          background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/nav-next.svg");
        }
        &[disabled] {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
    }
    &-caption {
      top: calc(50% + 38vw);
      left: 0;
      bottom: auto;
      text-align: left;
      padding: 0 20px;
      max-width: 780px;
      margin: 0 auto;
      background: none;
    }
  }
}

.fa-brands,.fab {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-weight: 400;
}

.fa-facebook:before {
  content: "\f09a"
}
.fa-x-twitter:before {
  content: "\e61b"
}
.fa-line:before {
  content: "\f3c0"
}

#zendesk-chat-widget {
  z-index: 9;
}


// Animations
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes move {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(-50%, -35%);
  }
  50% {
    transform: translate(-50%, -50%);
  }
  75% {
    transform: translate(-50%, -65%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes shine {
  0% {
    background-color: #c18b35;
  }
  8% {
    background-color: #ffd086;
  }
  100% {
    background-color: #c18b35;
  }
}
@keyframes shine-red {
  0% {
    background-color: #b31111;
  }
  8% {
    background-color: #ff8383;
  }
  100% {
    background-color: #b31111;
  }
}