.od-loader {
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.31373);
  top: 0;
  bottom: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-loader__spinner {
  height: 40px;
  width: 40px;
  -webkit-animation: rotate 0.8s infinite linear;
  animation: rotate 0.8s infinite linear;
  border: 5px solid #000000;
  border-right-color: transparent;
  border-radius: 20px;
  will-change: transform;
  margin: 0 auto;
}
