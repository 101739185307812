@import '../../../theme/mobile/variables';


.od-userinfo-wrapper {
    padding-bottom: 15px;
    margin: 46px 0 0;

    .od-userinfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__content {
            display: flex;
            align-items: center;

            &__icon {
                margin-right: 15px;
    
                img {
                    vertical-align: middle;
                }
            }
    
            &__name {
                color: #000000;
                font-weight: 400;
                font-size: 24px;
                margin-bottom: 16px;
            }
    
            &__login {
                color: #000000;
                font-weight: 400;
                font-size: 14px;
                opacity: 0.7;
            }
        }

        &__user-btns-toggler {
            transition: transform 0.3s ease-out;
            transform: rotate(180deg);

            &--active {
                transform: none;
            }
        }
    }

    .nav-title {
        margin: 0 0 4px;
        padding: 0 0 4px 2px;
        color: map-get($colorpallete, topaz);
        text-transform: uppercase;
        font-weight: 700;
        border-bottom: 2px solid currentColor;
    }
    
    .days-divider {
        background:  #FFB400;
        width: 1px;
    }
}

.od-membership-current-rank {
    text-transform: capitalize;
}